import React, {useState} from 'react'
import { PageTemplate } from '../templates/page'
import Collapsible from 'react-collapsible'
import CTA from '../components/shortcodes/CTA'
import CompatibilityPageStyles from '../styles/CompatibilityPage.module.sass'

const compatibleVehicles = require('../data/compatible-vehicles.json')

const meta = {
  title: "EV Charger Compatibility",
  pageIdentifier: "ev-charger-compatibility",
  pageClass: "hide-cart",
  showContactForm: true,
  ctaText: "Contact Us",
  seoMainKeyword: "ev charger compatibility",
  seoTitle: "Electric Car Charger Compatibility | What EV Charger Works for My Electric Car?",
}

const CompatibilityPage = () => {
  // Create accordion state database
  let _accordionStates = {}

  Object.keys(compatibleVehicles).forEach(item => {
    _accordionStates[item] = false
  })

  const [accordionStates, setAccordionStates] = useState(_accordionStates)

  function updateAccordionState (accordion, forceOpen) {
    let _accordionStates = accordionStates

    if ( forceOpen ) {
      _accordionStates[accordion] = true

      window.scrollTo({
        top: document.querySelectorAll('.accordion-id-' + sanitiseString(accordion))[0].getBoundingClientRect().top + window.scrollY,
        behavior: "smooth"
      })
    }
    else {
      _accordionStates[accordion] = !_accordionStates[accordion]
    }

    setAccordionStates({..._accordionStates})
  }

  function sanitiseString(string) {
    return string.replace(/\s+/g, '-').toLowerCase()
  }

  return (
    <PageTemplate 
      title={meta.title}
      pageIdentifier={meta.pageIdentifier}
      pageClass={meta.pageClass}
      showContactForm={meta.showContactForm}
      ctaText={meta.ctaText}
      seoTitle={meta.seoTitle}
    >
      <p className="has-text-centered is-size-5"><strong className="has-text-link">ALL</strong> our chargers are compatible with <strong className="has-text-link">ALL</strong> hybrid and electric vehicles that support Mode 3 charging with a Type 2 cable.</p>

      <div className={`container is-wider-than-parent ${CompatibilityPageStyles.brands || ''}`}>
        <strong className="title is-4 is-5-mobile">Choose Make:</strong>
        <ul className={`columns is-mobile is-multiline is-centered ${CompatibilityPageStyles.brandsList || ''}`}>
          {
            Object.entries(compatibleVehicles).map((item) => {
              return (
                <li // eslint-disable-line
                  key={item[0]}
                  className={`column is-2-desktop is-one-quarter-tablet is-one-third-mobile ${CompatibilityPageStyles.brand || ''}`}
                  onClick={() => updateAccordionState(item[0], true)}
                >
                  <div>
                    <img src={'/media/car-brands/' + sanitiseString(item[0]) + '.png'} alt={item[0]} title={item[0]} />
                    <strong>{item[0]}</strong>
                  </div>
                </li>
              )
            })
          }
        </ul>
      </div>

      <hr className="is-wider-than-parent" />

      <div className={`container ${CompatibilityPageStyles.database || ''}`}>
        {
          Object.entries(compatibleVehicles).map((item) => {
            return (
              <Collapsible 
                key={item[0]}
                trigger={item[0]}
                open={accordionStates[item[0]]}
                handleTriggerClick={() => updateAccordionState(item[0])}
                classParentString={`Collapsible accordion-id-${sanitiseString(item[0])} ${CompatibilityPageStyles.collapsible}`}
              >
                <ul className={`columns is-multiline`}>
                  {item[1].map(subItem => {
                    return (
                      <li 
                        key={subItem} 
                        dangerouslySetInnerHTML={{ __html: subItem }} 
                        className={`column is-one-third-desktop is-half-tablet`}
                      />
                    )
                  })}

                  <p className="has-text-centered"><strong>ALL</strong> our chargers are compatible with <strong>ALL</strong> hybrid and electric vehicles that support Mode 3 charging with a Type 2 cable. If your {item[0]} vehicle is not listed here but was produced recently for the Irish or the UK market, <strong>it’s almost certainly compatible</strong>.</p>
                </ul>
              </Collapsible>
            )
          })
        }
      </div>

      <CTA before="Get expert advice!" />
    </PageTemplate>
  )
}

export default CompatibilityPage